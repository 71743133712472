import APIService from '@/services/api-service'
const resource = '/job/repository'

class RepositoryService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getClients () {
    return this.connector.get(`${resource}/clients`)
  }

  public getCompanies () {
    return this.connector.get('/company/names')
  }

  public getServices () {
    return this.connector.get(`${resource}/services`)
  }
}

export default new RepositoryService()
