import APIService from '@/services/api-service'
const resource = '/job'
const subResource = 'team'

class TeamService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getStaffLabour () {
    return this.connector.get(`${resource}/${subResource}/staff-labour`)
  }

  public create (data: any) {
    return this.connector.post(`${resource}/${subResource}/create`, data)
  }

  public getAllocatedTeam (jobId) {
    return this.connector.get(`${resource}/${subResource}/${jobId}/allocated-team`)
  }

  public deleteTeam (payload: any) {
    return this.connector.delete(`${resource}/${subResource}/members`, { data: payload })
  }

  public deleteMember (payload: any) {
    return this.connector.delete(`${resource}/${subResource}/del/participant`, { data: payload })
  }

  public importParticipants (jobId: number, data) {
    return this.connector.post(`${resource}/${subResource}/${jobId}/import-participants`, data)
  }
}

export default new TeamService()
