import APIService from '@/services/api-service'
const resource = '/employee'

class EmployeeService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getStaff () {
    return this.connector.get(`${resource}/type-permanent`)
  }

  public getAllEmployees () {
    return this.connector.get(`${resource}/all`)
  }

  public getCOfficersInCharge () {
    return this.connector.get(`${resource}/officers-in-charge`)
  }

  public save (data: any) {
    return this.connector.post(`${resource}/save`, data)
  }

  public createStaffMember (data: any) {
    return this.connector.post(`${resource}/create/staff-member`, data)
  }

  public saveBasic (data: any) {
    return this.connector.post(`${resource}/save/basic-data`, data)
  }

  public saveAddress (data: any) {
    return this.connector.post(`${resource}/address/save`, data)
  }

  public saveBankDetails (data: any) {
    return this.connector.post(`${resource}/bank-details/save`, data)
  }

  public updateStaffMember (data: any) {
    return this.connector.put(`${resource}/update/staff-member`, data)
  }

  public getEmployeeByCategory () {
    return this.connector.get(`${resource}/by-category`)
  }

  public getEmployee (id) {
    return this.connector.get(`${resource}/details/${id}`)
  }

  // Labour Routes
  public getHelpers () {
    return this.connector.get(`${resource}/type-casual/helpers`)
  }

  public createLabour (data: any) {
    return this.connector.post(`${resource}/create/labour`, data)
  }

  public updateLabour (data: any) {
    return this.connector.put(`${resource}/update/labour`, data)
  }

  public getDrivers () {
    return this.connector.get(`${resource}/driver-details`)
  }
}

export default new EmployeeService()
