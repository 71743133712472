class DateHelper {
    protected date = new Date()

    public fullDate () {
      this.date.setDate(this.date.getDate())

      return this.date.getFullYear() + '/' + ('0' + (this.date.getMonth() + 1)).slice(-2) + '/' + ('0' + this.date.getDate()).slice(-2)
    }

    public year () {
      return this.date.getFullYear()
    }

    public yearMonth () {
      this.date.setDate(this.date.getDate())

      return this.date.getFullYear() + '/' + ('0' + (this.date.getMonth() + 1)).slice(-2)
    }

    public twoDigitYearMonth () {
      this.date.setDate(this.date.getDate())

      return this.date.getFullYear().toString().substr(-2) + '/' + ('0' + (this.date.getMonth() + 1)).slice(-2)
    }

    public today (format: string) {
      let month = '' + (this.date.getMonth() + 1)
      let day = '' + this.date.getDate()
      const year = this.date.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }
      if (day.length < 2) {
        day = '0' + day
      }

      switch (format) {
        case 'dd/mm/yyyy':
          return [day, month, year].join('/')

        case 'mm/dd/yyyy':
          return [month, day, year].join('/')

        case 'yyyy-mm-dd':
          return [year, month, day].join('-')

        case 'yyyymmdd':
          return [year, month, day].join('')
      }
    }

    public formatDate (date: Date) {
      let month = '' + (date.getMonth() + 1)
      let day = '' + date.getDate()
      const year = date.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }
      if (day.length < 2) {
        day = '0' + day
      }
      return [day, month, year].join('/')
    }

    public twoDigitYearMonthDate (date: Date) {
      return date.getFullYear().toString().substr(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2)
    }

    public fullDateTime (date: Date) {
      return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear().toString().substr(-2) + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2)
    }
}
export default new DateHelper()
