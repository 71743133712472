import APIService from '@/services/api-service'
const resource = '/job'
const subResource = 'letter'

class LetterService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getJobLetter (id: number) {
    return this.connector.get(`${resource}/${subResource}/${id}/body`)
  }

  public save (data: any) {
    return this.connector.post(`${resource}/${subResource}/save-body`, data)
  }

  public saveParticipant (data: any) {
    return this.connector.post(`${resource}/${subResource}/save-participant`, data)
  }

  public updateMember (data: any) {
    return this.connector.post(`${resource}/${subResource}/update-participant`, data)
  }
}

export default new LetterService()
