import APIService from '@/services/api-service'
const resource = '/member-list'

class MemberService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public saveMember (data: any) {
    return this.connector.post(`${resource}/save-member`, data)
  }

  public getMemberList () {
    return this.connector.get(`${resource}/member-details`)
  }
}

export default new MemberService()
