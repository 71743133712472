export const createTemplate = (referenceNumber, date, data, futureDate) => {
  return `
 <div>
 <p class="mb-2"><strong>${referenceNumber}</strong></p>
 <p class="mb-2">${date}</p>
 <p></p>
 <p class="mb-0">The Harbor Master, Sri Lanka Ports Authority,</p>
 <p class="mb-0">The Director Security, Control Room (security),</p>
 <p class="mb-0">CAPO, Operation Branch, Sri Lanka Custom,</p>
 <p class="mb-0">CAPO, Gate, Sri Lanka Customs, Colombo,</p>
 <p class="mb-0">The Manager Operations, JCT Terminals.</p>
 <p></p>
 <p><span style="text-transform: uppercase;margin-bottom: 1.1rem;"><strong><u>RE:Discharge  
    ${data.job.lines.map(line => `${line.service_type} `).join('')} from 
    <span>${data.job.vessel_name}</span>
    <span> of ${data.job.vessel_arrival_date} TO ${futureDate} carried & return 02 NOS, PUMPS & 02 NOS, HOSES</span> (GATE NO - 2, 8)</u></strong>
    </span>
 </p>
 <p>We are pleased to inform you that, the agent/master of the above vessel has appointed us to remove the following item(s)</p>
 <p>
 ${data.job.lines.map(line => `<strong>${line.service_type} - ${Math.round(line.estimated_volume)} ${line.unit_of_measurement}</strong> `).join('')}
   Using barge <span style="text-transform: uppercase;"><strong>Lanka Pride/HighSea Passage/Lanka Freedom, & Road Tanker/Trucks</strong></span>
 </p>
 <p>Accordingly, We shall be thankful if you kindly grant permission to enter the Colombo Port for
   the following vehicles and the personnel to attend operation.
 </p>
 </div>
 `
}

export default {
  createTemplate
}
