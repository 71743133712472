export const createTemplate = (referenceNumber, date, data, futureDate) => {
  return `
   <div>
   <p class="mb-2"><strong>${referenceNumber}</strong></p>
   <p class="mb-2">${date}</p>
   <p></p>
   <p class="mb-0">The Harbor Master, Sri Lanka Ports Authority, Port of Colombo, Colombo,</p>
   <p class="mb-0">The Director Security, Control Room (security), Sri Lanka Ports Authority, Colombo,</p>
   <p class="mb-0">Chief Assistance, Preventive Offices, Operation branch, Sri Lanka Custom.</p>
   <p class="mb-0">The Manager Operations, JCT/CICT/SAGT - Terminals.</p>
   <p></p>
   <p><span style="text-transform: uppercase;margin-bottom: 1.1rem;">
   <strong><u>SUBJECT: REMOVAL OF GARBAGE ON <span>${data.job.vessel_name}</span>
   <span> OF ${data.job.vessel_arrival_date} </span></u></strong></span>
   </p>
   <p>We are pleased to inform you that, the agent/master of the above vessel has appointed us to remove the</p>
   <p>
   ${data.job.lines.map(line => `<strong>${line.service_type === 'Garbage' ? line.service_type : null} - ${Math.round(line.estimated_quantity)} ${line.unit_of_measurement}</strong> `).join('')}
     Using Lorry.
   </p>
   <p>
      Accordingly, We shall be thankful if you kindly grant permission to enter the Colombo Port for
      the following vehicles and the personnel to remove Garbage on <strong>${data.job.vessel_arrival_date}</strong> to <strong>${futureDate}</strong>
   </p>
   <p>Shipping agent's/master's request letter is attached herewith.</p>
   </div>
   `
}

export default {
  createTemplate
}
