import APIService from '@/services/api-service'
const resource = '/job'
const subResource = 'vehicle'

class VehicleService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public create (data: any) {
    return this.connector.post(`${resource}/${subResource}/create`, data)
  }

  public save (data: any) {
    return this.connector.post(`${resource}/${subResource}/save`, data)
  }

  public getAllVehicles () {
    return this.connector.get('vehicle/all')
  }

  public getOperationVehicles (jobId) {
    return this.connector.get(`${resource}/${subResource}/${jobId}/allocated`)
  }

  public update (data: any) {
    return this.connector.put(`${resource}/${subResource}/update`, data)
  }

  public deleteVehicles (payload: any) {
    return this.connector.delete(`${resource}/${subResource}/deletes`, { data: payload })
  }

  public deleteVehicle (payload: any) {
    return this.connector.delete(`${resource}/${subResource}/delete`, { data: payload })
  }

  public getAllocatedVehicles (jobId) {
    return this.connector.get(`${resource}/${subResource}/${jobId}/allocated`)
  }

  public importVehicles (jobId: number, data) {
    return this.connector.post(`${resource}/${subResource}/${jobId}/import-vehicles`, data)
  }

  public getVehicles () {
    return this.connector.get('vehicle/list')
  }
}

export default new VehicleService()
