
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  JobModule
} from '../../../../store/JobModule'
import VehicleService from '../../../../services/vehicle-service'
import TeamService from '../../../../services/team-service'
  @Component({
    name: 'JobPermissionLetterPreviewModal',
    components: {}
  })
export default class JobPermissionLetterPreviewModal extends Vue {
    protected isData: any = false
    protected content: any = null
    protected teamMembers: any = []
    private operationVehicles: any = []
    protected company: any = {}

    protected letter: any = {
      id: null,
      job_id: null,
      job_number: null,
      job_line_id: null,
      job_line_number: null,
      reference_number: null,
      date: null,
      senders_details: null,
      title: null,
      body: null,
      created_user: null,
      modified_user: null
    }

    get participantsListHeight () {
      if (this.teamMembers.length % 2 === 0) {
        return this.teamMembers.length / 2 * 20
      } else {
        return this.teamMembers.length / 2 * 20 + 12
      }
    }

    created () {
      this.boot()
    }

    private boot () {
      this.populateOperationVehicles()
      this.populateTeam()
    }

    public showModal (content: any, letter: any, company: any) {
      this.content = content
      this.letter = letter
      this.company = company;
      (this.$refs.LetterPreviewModal as any).show()
    }

    public hideModal () {
      (this.$refs.LetterPreviewModal as any).hide()
    }

    protected async populateTeam () {
      this.teamMembers = []
      const response = await TeamService.getAllocatedTeam(JobModule.job.id)
      this.teamMembers = response.data
    }

    private async populateOperationVehicles () {
      const response = await VehicleService.getAllocatedVehicles(JobModule.job.id)
      this.operationVehicles = response.data.map(item => ({
        id: item.id,
        job_id: item.job_id,
        type: item.vehicle.type,
        vehicle_number: item.vehicle.vehicle_number,
        vehicle_id: item.vehicle.id
      }))
    }

    public close () {
      this.content = null
      this.hideModal()
    }
}

