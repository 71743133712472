
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  JobModule
} from '../../../../store/JobModule'

import VehicleService from '../../../../services/vehicle-service'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import VehicleTypes from '@/data/vehicle-types.json'
  @Component({
    name: 'JobVehicleModal',
    components: {
      // AddModal
    }
  })
export default class JobVehicleModal extends Vue {
    protected isEdit = false
    protected vehicleList: any = []
    protected vehicleTypes = VehicleTypes
    protected vehicleType = null
    private driverNames: any = []
    private operationVehicles: any = []
    public searchVehicle = null
    public vehicleListFields = [{
      key: 'allocate',
      label: '',
      thStyle: {
        width: '3%'
      },
      class: 'text-center'
    },
    {
      key: 'vehicle_number',
      label: 'Vehicle Number',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'type',
      label: 'Vehicle Type',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'ownership',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'wheels',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'capacity',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'driver',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'co_driver',
      thStyle: {
        width: '15%'
      }
    }
    ]

    public showModal (): void {
      this.populateVehicles();
      (this.$refs.vehicleModal as any).show()
    }

    public hideModal () {
      (this.$refs.vehicleModal as any).hide()
    }

    public openAddModal () {
      (this.$refs.addModal as any).showModal()
    }

    private async populateVehicles () {
      const response = await VehicleService.getVehicles()
      this.vehicleList = response.data.map(item => ({
        ...item,
        allocate: false
      }))
    }

    public isDriverDuplicated (value) {
      let count = 0
      this.vehicleList.forEach((vehicle) => {
        if (vehicle.allocate) {
          if (vehicle.driver === value) count++
        }
      })
      if (count > 1) ToastModule.message('JOB_VEH_DRA_WAR')
    }

    protected setOperationalVehicles () {
      // let isDriverAssigned = true
      this.vehicleList.forEach(vehicle => {
        if (vehicle.allocate) {
          this.operationVehicles.push({
            id: null,
            job_id: JobModule.job.id,
            // job_number: JobModule.job.id,
            // job_line_id: JobModule.line.job_line_id,
            // job_line_number: JobModule.line.number,
            vehicle_id: vehicle.id,
            // vehicle_number: vehicle.vehicle_number,
            // driver: vehicle.driver,
            // co_driver: vehicle.co_driver,
            created_user: AuthModule.user.fullname,
            modified_user: AuthModule.user.fullname
          })
        }
      })
      if (this.operationVehicles.length === 0) {
        ToastModule.message('GEN_EMPTY_ERR')
        return false
      }
      VehicleService.save(this.operationVehicles).then((response) => {
        ToastModule.message(response.data.message);
        (this.$parent as any).populateOperationVehicles()
        this.hideModal()
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })

      this.operationVehicles = []
    }

    private close () {
      this.vehicleList = []
      this.operationVehicles = []
      this.hideModal()
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

