
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  JobModule
} from '../../../../store/JobModule'

import {
  Editor,
  EditorContent,
  BubbleMenu
} from '@tiptap/vue-2'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Heading from '@tiptap/extension-heading'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import BulletList from '@tiptap/extension-bullet-list'
import History from '@tiptap/extension-history'
// import BubbleMenu from '@tiptap/extension-bubble-menu'

import RepositoryService from '../../../../services/repository-service'
import VehicleService from '../../../../services/vehicle-service'
import EmployeeService from '@/views/employee/services/employee-service'
import MemberService from '@/views/employee/services/member-service'
import TeamService from '../../../../services/team-service'
import LetterService from '../../../../services/letter-service'

import PreviewModal from './PreviewModal.vue'
import JobVehicleModal from '../Vehicle/JobVehicleModal.vue'
import Template from './template.js'
import GTemplate from './garbage-template.js'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import DateHelper from '@/mixins/date-mixins'
import {
  Printd
} from 'printd'
import Vehicle from '@/views/operation/Port.vue'
  @Component({
    name: 'JobPermissionLetter',
    components: {
      EditorContent,
      PreviewModal,
      BubbleMenu,
      JobVehicleModal
    }
  })
export default class JobPermissionLetter extends Vue {
    protected isLoader = false
    protected editor: any = null
    protected document: any = null
    public htmlContent: any = null
    public body: any = ''
    public members: any = []
    protected tempTeamList: any = []
    protected operationVehicles: any = []
    protected companies: any = []
    protected company: any = {}
    protected styles = ['https://api.smc-spl-application.com/print.css']
    protected letter: any = {
      id: null,
      job_id: null,
      job_line_id: null,
      date: null,
      body: null,
      signature: null,
      created_user: null,
      modified_user: null
    }

    protected participantCategories = ['Unassign Drivers', 'Staff', 'Labour']
    protected memberNames: any = []
    protected unassignDrivers: any = []
    protected staff: any = []
    protected labours: any = []
    protected lines: any = []
    protected line: any = {}
    protected participant: any = {
      id: null,
      job_id: null,
      employee_id: null,
      created_user: null,
      modified_user: null
    }

    protected teamMembers: any = []

    protected teamMember: any = []
    protected editTeamMember = {}

    mounted () {
      this.editor = new Editor({
        extensions: [
          Document,
          Paragraph,
          Text,
          Bold,
          Italic,
          Underline,
          OrderedList,
          ListItem,
          Heading,
          BulletList,
          History
        ]
      })
    }

    get job () {
      return JobModule.job
    }

    get participantsListHeight () {
      if (this.teamMembers.length % 2 === 0) {
        return this.teamMembers.length / 2 * 20
      } else {
        return this.teamMembers.length / 2 * 20 + 12
      }
    }

    get participantsVehicleHeight () {
      if (this.operationVehicles.length % 2 === 0) {
        return this.operationVehicles.length / 2 * 20
      } else {
        return this.operationVehicles.length / 2 * 20 + 12
      }
    }

    created () {
      this.boot()
    }

    private boot () {
      this.document = new Printd()
      this.populateJobLines()
      this.populateFields()
      this.populateOperationVehicles()
      this.populateEmployees()
      this.populateTeam()
      this.populateCompanyNames()
    }

    public openVehicleModal () {
      (this.$refs.JobVehicleModal as any).showModal()
    }

    public openEmployeeModal () {
      (this.$refs.EmployeeModal as any).showModal()
    }

    protected setCompanyName () {
      this.letter.signature = 'Yours faithfully,' + '\n' + this.company.name + '\n\n' + 'B.K.A.T. Perera' + '\n' +
        'Manager operations' + '\n' + '0778370786'
    }

    private async populateCompanyNames () {
      const response = await RepositoryService.getCompanies()
      response.data.forEach((element) => {
        this.companies.push({
          value: {
            id: element.id,
            name: element.name
          },
          text: element.name
        })
      })
    }

    protected populateJobLines () {
      this.job.lines.forEach((line: any) => {
        this.lines.push({
          value: {
            service_id: line.service_id,
            service_type: line.service_type
          },
          text: line.service_type
        })
      })
    }

    protected async importParticipants () {
      this.teamMembers = []
      const data = {
        created_user: AuthModule.user.fullname
      }
      const response = await TeamService.importParticipants(JobModule.job.id, data)
      this.teamMembers = response.data
    }

    protected async importVehicles () {
      this.operationVehicles = []
      const data = {
        created_user: AuthModule.user.fullname
      }
      const response = await VehicleService.importVehicles(JobModule.job.id, data)
      this.operationVehicles = response.data
    }

    protected populateFields () {
      this.isLoader = true
      this.letter.date = DateHelper.today('dd/mm/yyyy')

      LetterService.getJobLetter(JobModule.job.id).then(response => {
        this.isLoader = false
        if (response.data.status === 'exist') {
          this.htmlContent = response.data.letter
          this.$nextTick(() => {
            this.editor.commands.setContent(response.data.letter.body)
          })
          return true
        }
        this.letter.futureDate = this.futureDate(response.data.job.vessel_arrival_date)
        this.$nextTick(() => {
          this.createTemplate(this.letter.date, response.data, this.letter.futureDate)
        })
      })
    }

    public createTemplate (date: string, data: any, futureDate: string) {
      if (this.line.service_type === 'Garbage') {
        this.body = GTemplate.createTemplate(JobModule.job.job_number, date, data, futureDate)
      } else {
        this.body = Template.createTemplate(JobModule.job.job_number, date, data, futureDate)
      }

      this.editor.commands.setContent(this.body)
      this.htmlContent = this.body
    }

    private async populateOperationVehicles () {
      const response = await VehicleService.getAllocatedVehicles(JobModule.job.id)
      this.operationVehicles = response.data.map(item => ({
        id: item.id,
        job_id: item.job_id,
        type: item.vehicle.type,
        vehicle_number: item.vehicle.vehicle_number,
        vehicle_id: item.vehicle.id
      }))
    }

    protected async populateTeam () {
      this.teamMembers = []
      const response = await TeamService.getAllocatedTeam(JobModule.job.id)
      this.teamMembers = response.data
    }

    protected async populateMemberList () {
      const response = await MemberService.getMemberList()
      this.members = response.data
    }

    protected async populateEmployees () {
      const response = await EmployeeService.getEmployeeByCategory()
      this.unassignDrivers = response.data.drivers
      this.staff = response.data.staff
      this.labours = response.data.labours
    }

    protected getParticipantCategory (value) {
      this.memberNames = []
      switch (value) {
        case 'Unassign Drivers':
          this.memberNames = this.unassignDrivers
          break
        case 'Staff':
          this.memberNames = this.staff
          break
        case 'Labour':
          this.memberNames = this.labours
          break
      }
    }

    protected saveParticipant () {
      this.participant.job_id = JobModule.job.id
      this.participant.created_user = AuthModule.user.fullname
      this.participant.modified_user = AuthModule.user.fullname

      LetterService.saveParticipant(this.participant).then((response) => {
        this.populateTeam()
        ToastModule.message(response.data.message)
      })
    }

    protected updateMember (editTeamMember) {
      const data = {
        job_id: JobModule.job.id,
        created_user: AuthModule.user.fullname,
        modified_user: AuthModule.user.fullname,
        id: editTeamMember.id,
        employee_id: this.participant.id
      }
      LetterService.updateMember(data).then(response => {
        console.log(response)
        this.populateTeam()
      })
    }

    protected removeMember (payload: any) {
      if (confirm(`Are you sure you want to delete ${payload.name}?`) === true) {
        const data = {
          employee_id: payload.employee_id,
          job_id: JobModule.job.id
        }
        TeamService.deleteMember(data).then(response => {
          this.populateTeam()
          this.populateOperationVehicles()
          ToastModule.message(response.data.message)
        })
      }
    }

    protected editMember (teamMember) {
      this.editTeamMember = teamMember
    }

    protected removeVehicle (payload: any) {
      if (confirm(`Are you sure you want to delete ${payload.vehicle_number}?`) === true) {
        const data = {
          vehicle_id: payload.vehicle_id,
          job_id: JobModule.job.id
        }
        VehicleService.deleteVehicle(data).then(response => {
          this.populateOperationVehicles()
          ToastModule.message(response.data.message)
        })
      }
    }

    protected save (type: string) {
      if (type === 'save') {
        this.create().then(response => {
          this.letter.date = response.data.date
          this.htmlContent = response.data.body
          ToastModule.message('GEN_SUC')
        }).catch(error => {
          console.log(error)
        })
      } else {
        this.create().then(response => {
          this.letter.date = response.data.date
          this.htmlContent = response.data.body
          ToastModule.message('GEN_SUC')
          this.print()
        }).catch(error => {
          console.log(error)
        })
      }
    }

    protected create () {
      this.letter.date = DateHelper.today('dd/mm/yyyy')
      this.letter.body = this.editor.getHTML()
      this.letter.job_id = JobModule.job.id
      this.letter.created_user = AuthModule.user.fullname
      this.letter.modified_user = AuthModule.user.fullname
      return LetterService.save(this.letter)
    }

    public preview () {
      this.htmlContent = this.editor.getHTML();
      (this.$refs.previewModal as any).showModal(this.htmlContent, this.letter, this.company)
    }

    public print () {
      this.$nextTick(() => {
        this.document.print(document.getElementById('printPermissionLetter'), this.styles)
      })
    }

    protected futureDate (vesselArrivalDate) {
      var vad = vesselArrivalDate.split('/').reverse().join('-')
      var d = new Date(vad)
      d.setDate(d.getDate() + 29)
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }
      if (day.length < 2) {
        day = '0' + day
      }

      return [day, month, year].join('/')
    }
}

